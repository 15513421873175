import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../layout/Header";
import ProductList from "../components/Lists/ProductList";
import { getCoupons } from "../service/GetCoupons";
import Footer from "../layout/Footer";
import { LoaderContext } from "../context/LoaderContext";
import { Page } from "../context/Pages";
import { getCouponData } from "../service/GetCouponData";

export default function LandingPage() {
  const [coupons, setCoupons] = useState(null);
  const { currentPage, setCurrentPage } = useContext(Page);

  const { setLoading } = useContext(LoaderContext);
  let [totalPages] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setLoading(true);

      // if(localStorage.getItem("loginType") === "mobile"){
      //     handleCoupons();
      // }else{
      //     handleCouponCode();
      // }

      handleCoupons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCoupons = async () => {
    const response = await getCoupons(currentPage);
    if (response?.data?.success) {
      // console.log("data", response?.data?.response)
      setCoupons(response?.data?.response.rows);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      navigate("/login");
    }
  };

  const handleCouponCode = async () => {
    const response = await getCouponData();
    setLoading(false);
    if (response && response?.success) {
      setCoupons(response?.data?.rows);
    }
    console.log(response);
    // setCoupons(response);
  };

  const handleNextPage = () => {
    let modular = coupons?.length % 10;
    let devider = (coupons?.length - modular) / 10;
    if (modular) {
      totalPages = devider + 1;
    } else {
      totalPages = devider;
    }
    // console.log("modulo", modular, devider, totalPages)
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="landing-page">
      <Header />
      <div className="content-container ">
        <div className="mc-product-list-container">
          <div
            style={{ padding: "1rem" }}
            className="mc-coupon-page-header d-flex justify-content-between"
          >
            <h3 className="total-items">
              Total Coupons({coupons?.length || coupons?.count || 0})
            </h3>
            <a href="/orders" className="text-decoration-none">
              <h3 className="total-items">Order History</h3>
            </a>
          </div>
          <ProductList
            coupons={coupons?.slice((currentPage - 1) * 10, currentPage * 10)}
            handleCoupons={handleCoupons}
            currPage={currentPage}
          />
        </div>
      </div>
      <div>
        <div className="mc-list-footer">
          <div className="mc-pagination-page-detail">
            Showing{" "}
            <span className="bold">
              {(currentPage - 1) * 10 + 1 || 0}-
              {currentPage * 10 > coupons?.length
                ? coupons?.length
                : currentPage * 10}
            </span>{" "}
            results out of <strong>{coupons?.length}</strong>
          </div>
          <div className="mc-pagination-buttons-container">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              <i class="fa-solid fa-angle-left"></i>
            </button>
            <button>{currentPage}</button>
            <button
              onClick={handleNextPage}
              disabled={parseInt(coupons?.length / 10) === currentPage - 1}
            >
              <i class="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
