// import axios from "axios";

// export const RedeemCoupon = async (id, coupon_code) => {
//         try {
//         if (!id || !coupon_code) {
//             throw new Error("Values are invalid");
//         }
//         const response = await axios.post(`${baseurl}/api/user/almond/`, { id, coupon_code }, {
//             headers: {
//                 "x-access-token": localStorage.getItem("token")
//             }
//         })
//         return response;
//     } catch (err) {
//         return err.message;
//     }
// }

// import axios from "axios";

// export const RedeemCoupon = async (id, coupon_code) => {
// try {
//     if (!id || !coupon_code) {
//         throw new Error("Values are invalid");
//     }
//     const response = await axios.post(`${baseurl}/api/user/qwickcilver/couponCreate`, { id, coupon_code }, {
//         headers: {
//             "x-access-token": localStorage.getItem("token")
//         }
//     })
//     return response;
//     } catch (err) {
//         return err.message;
//     }
// }

import axios from "axios";
import { baseurl } from "../constants";

export const RedeemCoupon = async (coupon_id, coupon_code) => {
    try {
        console.log({ coupon_id, coupon_code });
        const response = await axios.post(`${baseurl}/api/user/almond/coupancardcreate`, { coupon_id, coupon_code }, {
            headers: {
                "x-access-token": localStorage.getItem("token")
            }
        })
        return response;
    } catch (err) {
        return err.message;
    }
}
