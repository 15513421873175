import { useEffect, useState } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import axios from "axios";

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB");
}

export function getStatusMessage(statusCode) {
  switch (statusCode) {
    case 0:
      return "Pending";
    case 1:
      return "Order Placed";
    case 2:
      return "Delivered";
    case 3:
      return "Dispatch";
    case 4:
      return "Return";
    case 5:
      return "Cancelled";
    case 6:
      return "Rejected";
    case 7:
      return "Return Accepted";
    case 8:
      return "Return Rejected";
    case 9:
      return "Return Initiated";
    case 10:
      return "No Service";
    case 11:
      return "Confirmed";
    case 12:
      return "In Transit";
    case 13:
      return "Refund Price";
    case 14:
      return "Replacement Order";
    case 15:
      return "RTW Damage";
    case 16:
      return "RTW RTO";
    case 17:
      return "Lost In Transit";
    case 18:
      return "In Transit Damaged";
    case 19:
      return "Pickup Initiated";
    case 20:
      return "Pickup Received";
    case 25:
      return "Completed";
    default:
      return "Pending";
  }
}

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("No authentication token found.");
          setLoading(false);
          return;
        }

        const response = await axios.get(
          "https://almondsapi.vouch.club/api/user/order/history",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (Array.isArray(response.data.response.rows)) {
          setOrders(response.data.response.rows);
        } else {
          setOrders([]);
          setError("The response data is not in the expected array format.");
        }

        setLoading(false);
      } catch (error) {
        setError("Failed to fetch orders");
        setLoading(false);
      }
    };

    const interval = setTimeout(() => {
      fetchOrders();
    }, 10);

    return () => clearInterval(interval);

  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="landing-page d-flex flex-column">
      <Header />
      <div className="content-container ">
        <div
          className="mc-product-list-container"
          style={{ minHeight: "unset" }}
        >
          <div
            style={{ padding: "1rem", marginBottom: "unset" }}
            className="mc-coupon-page-header d-flex justify-content-between"
          >
            <h3 className="total-items">Order History</h3>
          </div>
        </div>
        <div className="content-container flex-fill">
          {/* <div
            style={{ padding: "1rem" }}
            className="mc-coupon-page-header d-flex justify-content-between"
          >
            <h3 className="total-items">Order History</h3>
          </div> */}
          <ul className="list-unstyled">
            {Array.isArray(orders) && orders.length > 0 ? (
              orders.map((order, index) => (
                <div
                  key={index}
                  className="mc-product-item border rounded p-3 mb-3"
                >
                  <div className="d-flex gap-3 flex-wrap">
                    <div className="mc-product-image-container col-md-3">
                      <div className="card product-card-image">
                        <img
                          className="mc-product-image"
                          src={order?.image}
                          alt="order"
                        />
                      </div>
                      <div
                        className="mc-product-desc-container mc-coupon-code-container"
                        style={{ margin: "auto" }}
                      >
                        <h3 className="mc-product-name">
                          {order?.coupon_code}
                        </h3>
                      </div>
                    </div>
                    <div className="mb-2 mb-md-0 d-flex flex-column justify-content-center p-md-5">
                      <div>
                        <b>Order ID:</b> {order?.order_number}
                      </div>
                      <div>
                        <b>Order Date:</b> {formatDate(order?.redeem_date)}
                      </div>
                      <div>
                        <b>Order Title:</b> {order?.title}
                      </div>
                      {order?.address?.tracking_number && (
                        <div>
                          <b>AWB:</b> {order?.address?.tracking_number}
                        </div>
                      )}

                      <div>
                        <b>Delivery Address:</b> {order?.address?.street},
                        {order?.address?.city}, {order?.address?.state},
                        {order?.address?.country}
                      </div>
                      {order?.address?.delivered_date && (
                        <div>
                          <b>Delivery Date:</b>{" "}
                          {formatDate(order?.address?.delivered_date)}
                        </div>
                      )}
                      {order?.address?.delivery_partner && (
                        <div>
                          <b>Delivery Partner:</b>{" "}
                          {order?.address?.delivery_partner}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mc-message-container side-descriptor d-flex align-items-center col-md-3">
                    <div className="text-wrap text-left side-description">
                      {order?.type == "digital" ? "Delivered" : getStatusMessage(
                        parseInt(order?.address?.delivery_status, 10)
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No orders available.</div>
            )}
          </ul>
        </div>
      </div>
      <div className="mc-list-footer"></div>
      <Footer />
    </div>
  );
}
