import axios from "axios";
import { baseurl } from "../constants";

export const loginWithOtp = async (otp, mobile) => {
    try {
        const response = await axios.post(`${baseurl}/api/user/otpverify`, { mobile, otp });
        return response.data;
    } catch (err) {
        return err.message;
    }
}