import axios from "axios";
import { baseurl } from "../constants";
export const loginWithMobile = async (mobile) => {
    try {
        const response = await axios.post(`${baseurl}/api/user/login/mobile`, {
            mobile,
            project_name: "USV Rewards"
        }, {
            headers: {
                "Accept": "*/*"
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}