import { useEffect, useRef, useState } from "react";
// import data from "./Data.json";
import axios from "axios";
import { baseurl } from "../../constants";
export default function AddressForm({ couponData, onSuccess, onFailure, onCancel }) {


    const fetchAddress = async () => {
        try {

            const response = await axios.get(`${baseurl}/api/user/fetch/address`, {
                headers: {
                    "x-access-token": localStorage.getItem("token")
                }
            })

            console.log(response.data);

            if (response.data.user) {
                setAutoFillAddress({
                    city: response?.data?.user?.city || "",
                    state: response?.data?.user?.state || "",
                    street: response?.data?.user?.street || "",
                    zipCode: response?.data?.user?.zipCode || "",
                    district: response?.data?.user?.district || "",
                    country: response?.data?.user?.country || "",
                })
                setForm({
                    city: response?.data?.user?.city || "",
                    state: response?.data?.user?.state || "",
                    street: response?.data?.user?.street || "",
                    zipCode: response?.data?.user?.zipCode || "",
                    district: response?.data?.user?.district || "",
                    country: response?.data?.user?.country || "",
                })
            }

        } catch (Err) {
            return Err.message;
        }
    }

    useEffect(() => {

        const interval = setTimeout(() => {
            (async () => { await fetchAddress() })();
        }, 10);

        return () => clearInterval(interval);

    }, [])

    const [autoFillAddress, setAutoFillAddress] = useState();

    console.log(couponData.address);

    const [form, setForm] = useState();
    const formRef = useRef(null);

    useEffect(() => {
        if (formRef) {
            formRef.current.reset();
        }
    }, [])

    const handleChange = (e) => {
        try {

            const { name, value } = e.target;

            console.log(value);

            if (name === "zipCode" && value.length === 6) {
                handleStateAutoFill(value);
                setForm({ ...form, [name]: value });
            }
            setForm({ ...form, [name]: value });


        } catch (err) {
            return err.message;
        }
    }

    const handleSubmit = async (e) => {
        try {

            e.preventDefault();

            if (!couponData || !couponData.id || !couponData.coupon_code) {
                throw new Error(`Coupon Data Not Provided`);
            }

            console.log({ form });

            const response = await axios.post(`${baseurl}/api/user/almonds/order/address/submit`, {
                "id": couponData.id.toString(),
                "coupon_code": couponData.coupon_code,
                "street": form.street.toString().trim(),
                "city": form.city.toString().trim(),
                "state": form.state.toString().trim(),
                "zipCode": form.zipCode.toString().trim(),
                "district": form.district.toString().trim()
            }, {
                headers: {
                    "x-access-token": localStorage.getItem("token")
                }
            })

            if (response) {
                onSuccess({ response: response.data })
            }

            formRef.current.reset();


        } catch (err) {

            console.log(err.message);

            if (onFailure)
                onFailure({ message: err.message, couponData: couponData })

        }
    }



    const handleStateAutoFill = async (zipCode) => {
        try {

            const response = await axios.get(`${baseurl}/api/user/almonds/pincode?pincode=${zipCode}`);

            if (response.status === 200) {

                let { State, District } = response.data.data.rows[0];

                if (District) {
                    document.getElementById("District").value = District;
                    setForm({ ...form, district: District });
                }

                if (State) {
                    document.getElementById("State").value = State;
                    setForm({ ...form, state: State });
                }

                if (District && State) {
                    setForm({ ...form, state: State, district: District, zipCode: zipCode });
                }
            }

        } catch (err) {
            return err.message;
        }
    }


    return <form
        className="mc-address-form"
        onSubmit={handleSubmit}
        ref={formRef}
    >

        <h3>Enter Delivery Address</h3>


        {/* <div>
            <select name="state" className="mc-input" required onChange={handleChange}>
                <option value="">Select Your State</option>
                {
                    data.states.map((item) => {
                        return <option value={item.name}>{item.name}</option>
                    })
                }
            </select>
        </div>

        <div>
            <select name="district" className="mc-input" required>
                <option value="">Select Your District</option>
                {
                    districts?.map((item) => {
                        return <option value={item.name}>{item.name}</option>
                    })
                }
            </select>

        </div> */}

        <div>
            <textarea
                // cols={50}
                style={{ width: "100%" }}
                rows={5}
                name="street"
                defaultValue={autoFillAddress?.street}
                className="mc-input"
                required
                type="text"
                placeholder="Full Address"
                onChange={handleChange} />
        </div>




        <div>
            <input
                name="city"
                className="mc-input"
                required
                type="text"
                placeholder="City"
                pattern="[A-Za-z ]{2,}"
                title="City name can't include number or symbols."
                defaultValue={autoFillAddress?.city}
                onChange={handleChange} />
        </div>


        <div>
            <input
                name="zipCode"
                className="mc-input"
                required
                type="text"
                placeholder="Pin Code"
                pattern="\d{6}"
                title="ZIP Code should be 6-digit number only."
                defaultValue={autoFillAddress?.zipCode}
                onChange={handleChange} />
        </div>


        <div>
            <input
                name="district"
                className="mc-input"
                required
                id="District"
                type="text"
                placeholder="District"
                pattern="[A-Za-z ]{2,}"
                title="District name can't include number or symbols."
                defaultValue={autoFillAddress?.district}
                onChange={handleChange} />
        </div>


        <div>
            <input
                name="state"
                className="mc-input"
                required
                type="text"
                id="State"
                placeholder="State"
                onChange={handleChange}
                pattern="[A-Za-z ]{2,}"
                title="State name can't include number or symbols."
                defaultValue={autoFillAddress?.state}
            />
        </div>



        <button type="submit">
            Confirm Order
        </button>
        {onCancel && <button type="button" className="btn-cancel" onClick={onCancel}>
            Cancel
        </button>}
    </form>

};