import axios from "axios";
import { baseurl } from "../constants";
export const getStatus = async (id, coupon_code) => {
    try {
        // console.log(id,coupon_code);
        const response = await axios.post(`${baseurl}/api/user/almond/rewardstatus`, { id, coupon_code }, {
            headers: {
                "x-access-token": localStorage.getItem("token")
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}

// import axios from "axios";
// export const getStatus = async (id, coupon_code) => {
//     try {
//         console.log(id,coupon_code);
//         const response = await axios.post(`${baseurl}/api/user/orderStatus`, { id, coupon_code },{
//             headers: {
//                 "x-access-token": localStorage.getItem("token")
//             }
//         });
//         return response;
//     } catch (err) {
//         return err.message;
//     }
// }