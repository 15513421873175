import { Modal } from "react-bootstrap";
import { getStatusMessage } from "../../pages/Orders";
import { useEffect, useState } from "react";

export default function TrackingModal({ coupon, trackingModal, setTrackingModal }) {
    const { address, redeem_date, activationUrl, order_number, updated_at, order_id } = coupon;

    console.log(address?.delivery_status);
    console.log(getStatusMessage(parseInt(address?.delivery_status)) || 0);

    const [trackingUrl, setTrackingUrl] = useState("");

    useEffect(() => {


        if (address?.delivery_partner && address?.tracking_number) {

            if (address?.delivery_partner?.toLowerCase() === "delhivery")
                setTrackingUrl(`https://www.delhivery.com/track-v2/package/${address?.tracking_number}`);
            else if (address?.delivery_partner?.toLowerCase() === "xpressbees")
                setTrackingUrl(`https://www.xpressbees.com/shipment/tracking?awbNo=${address?.tracking_number}`);
        }

    }, [
        address
    ]);


    return <>
        <Modal

            show={trackingModal}
            onHide={() => setTrackingModal(false)}
            onBackdropClick={() => setTrackingModal(false)}
            onClose={() => setTrackingModal(false)}
            centered
        >

            <Modal.Header closeButton></Modal.Header>

            <Modal.Body>

                <div className="mc-tracking-modal-content">

                    <ul>
                        <li className="mc-tracking-info">
                            {order_id && <p className="w-100"><span className="key"><bold>Order Id:</bold></span><span className="value">{order_id}</span></p>}
                            {order_number && <p className="w-100"><span className="key"><bold>Tracking Id:</bold></span><span className="value">{order_number}</span></p>}
                            {address?.tracking_number && <p className="w-100"><span className="key"><bold>AWB No:</bold></span><span className="value">{address?.tracking_number}</span></p>}
                            <p className="w-100"><span className="key"><bold>Status:</bold></span><span className="value">{getStatusMessage(parseInt(address?.delivery_status) || 0) || "Order Placed"}</span></p>
                            {redeem_date && <p className="w-100"><span className="key"><bold>Order Date:</bold></span><span className="value">{`${new Date(redeem_date).toDateString()}`}</span></p>}
                            {address?.delivered_date && <p className="w-100"><span className="key"><bold>Delivered On:</bold></span><span className="value">{`${new Date(address?.delivered_date).toDateString()}`}</span></p>}
                            {address?.delivery_partner && <p className="w-100"><span className="key"><bold>Delivery Partner:</bold></span><span className="value">{address?.delivery_partner}</span></p>}

                            {trackingUrl && <p className="w-100"><span className="key"><bold>Tracking URL:</bold></span><span className="value">{

                                <a href={trackingUrl} target="_blank" rel="noreferrer">
                                    {trackingUrl}
                                </a>

                            }</span></p>}
                            {address && <p className="w-100"><span className="key"><bold>Address:</bold></span><span className="value">{`${address?.street}, ${address?.city}, ${address?.state}, ${address?.zipCode}`}</span></p>}

                            {(updated_at) && <p className="w-100"><span className="key"><bold>Last Updated at:</bold></span><span className="value">{`${new Date(updated_at).toDateString()}`}</span></p>}

                            {['1', '12'].includes(address?.delivery_status) && <p className="w-100" style={{ fontSize: 14, fontStyle: "italic" }}><span className="key"><span>Note:</span></span><span className="value" style={{ color: "#666", fontSize: 16 }}>{`Your product is scheduled to be delivered within 15 business days from the date of order.`}</span></p>}

                            {/* <span>
                                Tracking Details will be shared once order will be dispatched
                            </span> */}
                        </li>
                    </ul>

                    {false && <ul>
                        <li><span>Order Number</span><span></span></li>
                        <li><span>Delivery Address</span><span> Agra, UP - 282001</span></li>
                        <li><span>Status</span><span>In Transit</span></li>
                        <li><span>Tracking Link</span><span></span></li>
                        <li><span>Last Updated at</span><span>{new Date().toISOString()}</span></li>
                    </ul>}
                </div>

            </Modal.Body>

        </Modal>
    </>
}